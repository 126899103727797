/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2025 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box, DialogContentText } from '@mui/material'
import axios from 'axios'
import noop from 'lodash/noop'
import { makeValidate } from 'mui-rff'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import TextFieldWrapper from 'components/forms/TextFieldWrapper'
import FormModal from 'components/modals/FormModal'
import utils from 'components/react_components/utils'
import { useCourseActions } from 'hooks/data/courses'
import { useCourseAuthors } from 'hooks/data/user'
import useCheckEmail from 'hooks/useCheckEmail'
import parseCommaSeparatedEmails from 'utils/parseEmailList'

export default function InviteAuthorsModal({
    course,
    onHide = noop
}: {
    course: { id: number }
    onHide?: (...args: any) => any
}) {
    const [templateSubject, setTemplateSubject] = useState('')
    const [templateContent, setTemplateContent] = useState('')
    const [loading, setLoading] = useState(true)
    const { data: courseActions } = useCourseActions(course.id)
    useCheckEmail()
    const { data: courseAuthors } = useCourseAuthors({
        user_has_course_invitation: course.id
    })

    const schema = Yup.object().shape({
        recipients: Yup.string().required(gettext('Required')).checkEmail('Check Email'),
        subject: Yup.string().required(gettext('Required')),
        content: Yup.string().required(gettext('Required'))
    })

    const validate = makeValidate(schema)

    useEffect(() => {
        async function fetchData() {
            try {
                const courseInvitationTemplateResponse = await axios.get(
                    window.Urls['publishing:courseinvitation_template'](course.id)
                )
                setTemplateSubject(courseInvitationTemplateResponse.data.subject)
                setTemplateContent(courseInvitationTemplateResponse.data.body)
            } catch (error) {
                utils.defaultAjaxErrorHandler(error)()
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [course.id])

    const onSubmit = async values => {
        const recipients = parseCommaSeparatedEmails(values.recipients)
        try {
            await axios.post(
                window.Urls['publishing:courseinvitation_list'](),
                recipients.map(recipient => ({ course: course.id, user: recipient }))
            )
            await axios.post(courseActions.sendCourseInvitations, {
                recipients,
                subject: values.subject,
                content: values.content,
                course: values.course
            })
            onHide()
        } catch (error) {
            // 400 means server validation failed. Show errors in form
            if (error.isAxiosError && error.response?.status === 400) {
                if (Array.isArray(error.response.data)) {
                    // It's kind of strange how the errors are returned by the serializer and the view.
                    // Sometimes we get here an array with a string, sometimes an array with objects and
                    // sometimes just a string. This is strangely depending on whether one email or more
                    // are sent and also depending on whether the erroneous email is sent first or second.
                    return {
                        recipients: error.response.data
                            .map(d => {
                                if (Array.isArray(d)) {
                                    return d[0]
                                }

                                if (d.user) {
                                    return d.user
                                }

                                if (typeof d === 'string') {
                                    return d
                                }
                                return ''
                            })
                            .filter(r => r !== '')
                            .join(', ')
                    }
                }
                return { recipients: '' }
            }

            utils.defaultAjaxErrorHandler(error)(
                gettext('Sorry! An error occurred while sending out course invitations.')
            )
        }

        return {}
    }

    const alreadyInvitedAuthors = () => {
        if (!courseAuthors) {
            return ''
        }
        return courseAuthors.map(author => author.userNameForDisplay).join(', ')
    }

    return (
        <FormModal
            title={gettext('Invite Authors')}
            subtitle={
                <Box>
                    <DialogContentText>
                        {gettext('Invite authors to contribute cases, PDFs, videos, etc. to course')}
                    </DialogContentText>
                    <DialogContentText>
                        {interpolate(gettext('Already Invited: %s'), [alreadyInvitedAuthors()])}
                    </DialogContentText>
                </Box>
            }
            submitText={gettext('Invite Authors')}
            open
            onReject={onHide}
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
                subject: templateSubject,
                content: templateContent,
                course: course.id
            }}
        >
            <Box mt={1}>
                <TextFieldWrapper
                    name="recipients"
                    label={gettext('Email address required. Separate multiple addresses with ,')}
                    variant="outlined"
                    type="text"
                    disabled={loading}
                />
            </Box>
            <TextFieldWrapper
                name="subject"
                label={gettext('Subject')}
                variant="outlined"
                type="text"
                disabled={loading}
            />
            <TextFieldWrapper
                name="content"
                label={gettext('Content')}
                multiline
                rows={10}
                variant="outlined"
                disabled={loading}
            />
        </FormModal>
    )
}
