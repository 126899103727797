/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2025 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box, Chip, DialogContentText } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import axios from 'axios'
import { makeValidate } from 'mui-rff'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import * as Yup from 'yup'

import TextFieldWrapper from 'components/forms/TextFieldWrapper'
import ConfirmModal from 'components/modals/ConfirmModal'
import FormModal from 'components/modals/FormModal'
import utils from 'components/react_components/utils'
import { useActiveUser, useCourseInstructors } from 'hooks/data/user'
import useCheckEmail from 'hooks/useCheckEmail'
import useUrls from 'hooks/useUrls'
import parseCommaSeparatedEmails from 'utils/parseEmailList'

export default function ManageCourseInstructorsModal({
    course,
    onHide
}: {
    course: { id: number }
    onHide: (...args: any) => any
}) {
    const urls = useUrls()
    const { data: currentUser } = useActiveUser()
    const { data: courseInstructors } = useCourseInstructors()
    const [courseInstructorBeingRemoved, setCourseInstructorBeingRemoved] = useState(null)
    const { enqueueSnackbar } = useSnackbar()

    useCheckEmail()
    const schema = Yup.object().shape({
        newCourseInstructors: Yup.string().required(gettext('Required')).checkEmail('Check Email')
    })
    const validate = makeValidate(schema)

    const alreadyInvitedInstructors = courseInstructors
        ? courseInstructors
              .filter(instructor => instructor.coursesWithAuthorships.includes(course.id))
              .sort((c1, c2) => c1.userNameForDisplay.localeCompare(c2.userNameForDisplay))
        : []

    const InvitedInstructorsComponent = () => {
        if (!alreadyInvitedInstructors) {
            return <div />
        }
        return (
            <Box display="flex" flexWrap="wrap" gap="8px">
                {alreadyInvitedInstructors.map(author => (
                    <Chip
                        key={author.username}
                        component="p"
                        size="small"
                        label={author.userNameForDisplay}
                        sx={{ margin: 0 }}
                        onDelete={
                            alreadyInvitedInstructors.length === 1 || currentUser.id === author.id
                                ? null
                                : () => {
                                      setCourseInstructorBeingRemoved(author)
                                  }
                        }
                    />
                ))}
            </Box>
        )
    }

    const queryClient = useQueryClient()
    const invalidateQueries = () => queryClient.invalidateQueries({ queryKey: ['CourseInstructor', 'list'] })

    const addCourseInstructors = useMutation({
        mutationFn: async (values: { newCourseInstructors: string }) => {
            const newCourseInstructors = parseCommaSeparatedEmails(values.newCourseInstructors)
            const res = await axios.post(urls.courseAddCourseInstructors(course.id), {
                newCourseInstructors
            })
            return res.data
        },
        onError: (error: AxiosError) => {
            // 400 means server validation failed. Show errors in form
            if (error.isAxiosError && error.response?.status === 400) {
                const message = Object.values(error.response.data).join(', ')
                enqueueSnackbar(message, { variant: 'error' })
            } else {
                utils.defaultAjaxErrorHandler(error)()
            }
        },
        onSuccess: (data, values: { newCourseInstructors: string }) => {
            const newCourseInstructors = parseCommaSeparatedEmails(values.newCourseInstructors)

            if (data.addedInstructors.length > 0) {
                enqueueSnackbar(
                    interpolate(
                        ngettext(
                            'Appointed %s as course instructor',
                            'Appointed %s as course instructors',
                            data.addedInstructors.length
                        ),
                        [data.addedInstructors.join(', ')]
                    ),
                    { variant: 'success' }
                )
                invalidateQueries()
                onHide()
            } else {
                enqueueSnackbar(
                    ngettext(
                        'Given author is already appointed as course instructor',
                        'Given authors are already appointed as course instructors',
                        newCourseInstructors.length
                    ),
                    { variant: 'warning' }
                )
            }
        }
    })

    const removeCourseInstructor = useMutation({
        mutationFn: () =>
            axios.post(urls.courseEditCourseInstructors(course.id), {
                newCourseInstructors: alreadyInvitedInstructors
                    .map(instructor => instructor.id)
                    .filter(id => id !== courseInstructorBeingRemoved.id)
            }),
        onSettled: () => setCourseInstructorBeingRemoved(null),
        onSuccess: invalidateQueries
    })

    if (!courseInstructors) {
        return null
    }

    return (
        <React.Fragment>
            <FormModal
                title={gettext('Add Co-Course Instructor')}
                subtitle={
                    <Box>
                        <DialogContentText>{gettext('Appoint author as course instructor')}</DialogContentText>
                        <Box display="flex" gap="8px">
                            <DialogContentText sx={{ whiteSpace: 'nowrap' }}>
                                {gettext('Already appointed:')}
                            </DialogContentText>
                            <InvitedInstructorsComponent />
                        </Box>
                    </Box>
                }
                submitText={gettext('Add')}
                open
                onReject={onHide}
                onSubmit={values => addCourseInstructors.mutate(values)}
                validate={validate}
            >
                <Box mt={1}>
                    <TextFieldWrapper
                        name="newCourseInstructors"
                        label={gettext('Email address required. Separate multiple addresses with ,')}
                        variant="outlined"
                        type="text"
                    />
                </Box>
            </FormModal>
            {courseInstructorBeingRemoved && (
                <ConfirmModal
                    title={gettext('Remove Course Instructor')}
                    contentText={interpolate(gettext('Do you really want to remove "%s" as course instructor?'), [
                        courseInstructorBeingRemoved.userNameForDisplay
                    ])}
                    submitText={gettext('Remove')}
                    onReject={() => setCourseInstructorBeingRemoved(null)}
                    onSubmit={() => removeCourseInstructor.mutate()}
                />
            )}
        </React.Fragment>
    )
}
