/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2025 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Link, MenuItem } from '@mui/material'
import React from 'react'

export default function CourseContributionActions({
    courseActions,
    setShareCourseOpen,
    setInviteAuthorsOpen,
    setManageCourseInstructorsOpen
}: {
    courseActions: CourseActions
    setInviteAuthorsOpen: (open: boolean) => void
    setShareCourseOpen: (open: boolean) => void
    setManageCourseInstructorsOpen: (open: boolean) => void
}) {
    return (
        <React.Fragment>
            {courseActions.shareCourse && (
                <Link onClick={() => setShareCourseOpen(true)}>
                    <MenuItem>{gettext('Share course')}</MenuItem>
                </Link>
            )}
            {courseActions.sendCourseInvitations && (
                <Link onClick={() => setInviteAuthorsOpen(true)}>
                    <MenuItem>{gettext('Invite authors')}</MenuItem>
                </Link>
            )}
            {courseActions.sendCourseInvitations && (
                <Link onClick={() => setManageCourseInstructorsOpen(true)}>
                    <MenuItem>{gettext('Manage course instructors')}</MenuItem>
                </Link>
            )}
        </React.Fragment>
    )
}
